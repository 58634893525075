import React, {useEffect, useState} from "react";

import './AddRecipient.sass'
import {Button, Form, Schema} from "rsuite";
import PhoneInput from "../PhoneInput/PhoneInput";
import {useTranslation} from "react-i18next";
import {ShippingReceiver} from "../../../interfaces/ShippingReceiver";

function TextField(props: any) {
    const { fieldName, value, setValue, accepter, label, ...rest } = props;
    return (
        <Form.Group controlId={`${fieldName}-3`} key={`${fieldName}-3`}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control
                name={fieldName}
                value={value}
                onChange={setValue}
                key={`${fieldName}-4`}
                accepter={accepter} {...rest}
            />
        </Form.Group>
    );
}

const AddRecipient: React.FC<{onNewRecipient: (receiver: ShippingReceiver) => void}> = ({onNewRecipient}) => {
    const { t } = useTranslation();
    const location = process.env.REACT_APP_LOCATION;

    const [formError, setFormError] = useState({});

    const [receiver, setReceiver] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        phone: ''
    })

    const { StringType } = Schema.Types;
    const errorField = t('form.requiredFieldMsg')
    const errorSymbols = t('form.errorSymbols')
    const model = Schema.Model({
        lastName: StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField),

        name: StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField),
        middleName: StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField)
    });

    const lastName = t('form.lastName')
    const firstName = t('form.firstName')
    const middleName = t('form.middleName')


    const lastNameChange = (value: string , event: any) => {
        if (value) setReceiver({...receiver, lastName: value})
    }

    const firstNameChange = (value: string , event: any) => {
        if (value) setReceiver({...receiver, firstName: value})
    }

    const middleNameChange = (value: string , event: any) => {
        if (value) setReceiver({...receiver, middleName: value})
    }


    const setPhoneValue = (phoneValue: string) => {

        let phone = phoneValue.replace(/[\(\)\-\+\_ ]/g, '');
        if((location == 'ua' && phone.length != 12) || !(phone.length >= 7 && phone.length <= 15)){
            setErrorVisible(true)
        } else {
            setErrorVisible(false)
        }
        if (phoneValue) setReceiver({...receiver, phone: phone})
    }

    const [errorVisible, setErrorVisible] = useState(false);
    const [isFormValid, setFormValid] = useState(false)


    const checkFormValidation = (error: any) => {

        if(error){
            setFormError(error)
        }

        if((location == 'ua' && Object.keys(receiver.phone).length != 12) || !(Object.keys(receiver.phone).length >= 7 && Object.keys(receiver.phone).length <= 15)){
            setErrorVisible(true)
        } else {
            setErrorVisible(false)
        }

        if(Object.keys(formError).length == 0 && !errorVisible){
            setFormValid(true)
        }

    }

    const saveRecipient = () => {

        if(isFormValid){
            onNewRecipient({...receiver})
        }

    }

    return(
        <div>
            <Form layout="inline" model={model}  onCheck={checkFormValidation} onSubmit={saveRecipient}>
                <div className={formError.hasOwnProperty('lastName') ? "input-wrapper has-error" : "input-wrapper"}>
                    <TextField fieldName="lastName" label={lastName} onChange={lastNameChange} />
                </div>
                <div  className={formError.hasOwnProperty('name') ? "input-wrapper has-error" : "input-wrapper"}>
                    <TextField fieldName="name" label={firstName} onChange={firstNameChange} />
                </div>
                <div className={formError.hasOwnProperty('middleName') ? "input-wrapper has-error" : "input-wrapper"}>
                    <TextField fieldName="middleName" label={middleName}  onChange={middleNameChange} />
                </div>
                <div className={errorVisible ? "input-wrapper has-error" : "input-wrapper"}>
                    <PhoneInput valueFunction={setPhoneValue} showError={errorVisible} />
                </div>
                <button
                    disabled={(Object.keys(formError).length || errorVisible) ? true: false}
                    className="btn btn-lg"
                    type="submit">
                    {t('order.save')}
                </button>
            </Form>
        </div>
    )
}
export default AddRecipient