import React, {ReactNode, SyntheticEvent, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Input, Schema, SelectPicker, Toggle} from "rsuite";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";


import novaPoshtaService from "../../../../../services/novaPoshtaService";
import {useAppSelector} from "../../../../../hooks";
import {ItemDataType} from "rsuite/cjs/@types/common";


import ShippingAddress from "../../../../../interfaces/ShippingAddress"
import {CarrierType} from "../../../../../enums/CarrierType";
import {DeliveryType} from "../../../../../enums/DeliveryType";

import "./DeliveryNewPost.sass"
import PaymentAndPickupData from "../../../../../interfaces/PaymentAndPickupData";
import User from "../../../../../interfaces/User";
import userService from "../../../../../services/userService";


interface SelectItem{
    label: string,
    value: string
}

const Field = React.forwardRef((props: any, ref: any) => {
    const { name, message, label, accepter, error, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : 'fdsfd'}>
            {error}
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} errorMessage={error} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});

const DeliveryNewPost: React.FC<{onChange: Function, shippingAddress?: ShippingAddress | null,
    onPaymentAndPickupDataChange?: (data: PaymentAndPickupData) => void, showPayOnDelivery: boolean, weight: number}> = (
    {onChange, shippingAddress, onPaymentAndPickupDataChange, showPayOnDelivery , weight}
) => {
    const {t} = useTranslation();
    const currentLang = useAppSelector<string>((state) => state.currentLang.value);
    const user = useAppSelector<User>(state => state.user.value)
    const [tabIndex, setTabIndex] = useState(0);
    const [areas, setAreas] = useState<SelectItem[]>([])
    const [cities, setCities] = useState<SelectItem[]>([])
    const [streets, setStreets] = useState<SelectItem[]>([])
    const [branches, setBranches] = useState<SelectItem[]>([])
    const [deliveryPayer, setDeliveryPayer] = useState([{label: t('deliveryPayer.sender'), value: 0}, {label: t('deliveryPayer.receiver'), value: 1}])
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedCity, setSelectedCity] = useState<{name: string | null, value: string | null}>({name: null, value: null});
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedStreet, setSelectedStreet] = useState('');


    const [address, setAddress] = useState<ShippingAddress>({
        carrierType: CarrierType.NewPost,
        deliveryType: DeliveryType.BranchDelivery,
        id: shippingAddress ? shippingAddress.id : null,
        state: shippingAddress ? shippingAddress.state : '',
        stateId:  shippingAddress ? shippingAddress.stateId : null,
        city: shippingAddress ? shippingAddress.city : '',
        cityId: shippingAddress ? shippingAddress.cityId : null,
        district: shippingAddress ? shippingAddress.district : '',
        districtId: shippingAddress ? shippingAddress.districtId : null,
        postalCode: shippingAddress ? shippingAddress.postalCode : null,
        street: shippingAddress ? shippingAddress.street : '',
        streetId: shippingAddress ? shippingAddress.streetId : null,
        house: shippingAddress ? shippingAddress.house : '',
        apartment: shippingAddress ? shippingAddress.apartment : '',
        carrierBranch: shippingAddress ? shippingAddress.carrierBranch : '',
        carrierBranchId: shippingAddress ? shippingAddress.carrierBranchId : null,
        country: 'Ukraine'
    })

    const formRef = useRef<any>();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({
        area: shippingAddress?.stateId,
        city: shippingAddress?.cityId,
        branch: shippingAddress?.carrierBranchId
    });
    const [formValueCourier, setFormValueCourier] = useState({
        area: shippingAddress?.stateId,
        city: shippingAddress?.cityId,
        street: shippingAddress?.street,
        house: shippingAddress?.house
    });
    const [formErrorAddress, setFormErrorAddress] = useState({});

    const area = t('delivery.area')
    //const district = t('delivery.district')
    const city = t('delivery.city')
    const branch = t('delivery.branch')
    const street = t('delivery.street')
    const house = t('delivery.house')
    const apartment = t('delivery.apartment')
    const payer = t('delivery.payer')
    const sum = t('delivery.sum')

    const { StringType } = Schema.Types;
    const errorField = t('form.requiredFieldMsg')
    const model = Schema.Model({
        area: StringType().isRequired(errorField),
        //district: StringType().isRequired(errorField),
        city: StringType().isRequired(errorField),
        branch: StringType().isRequired(errorField),
        //apartment: StringType().isRequired(errorField),
    });

    const modelAddress = Schema.Model({
        area: StringType().isRequired(errorField),
        //district: StringType().isRequired(errorField),
        city: StringType().isRequired(errorField),
        street: StringType().isRequired(errorField),
        house: StringType().isRequired(errorField),
        //apartment: StringType().isRequired(errorField),
    });

    useEffect(() => {
        if (shippingAddress){
            setTabIndex(shippingAddress.deliveryType)
            saveDeliveryType(shippingAddress.deliveryType)
        }
    }, [])

    useEffect(() => {
        novaPoshtaService.getAreas().then(data => {
            setAreas(data.map(item => ({ label: item.name, value: item.id })))
            if(shippingAddress?.stateId) {
                setSelectedArea(shippingAddress.stateId)
            }
            if (data.length == 0) {
                setCities([]);
                setBranches([]);
            }
        })
    }, [])

    const getStreets = async (value: string) => {
        const limit = 400;
        let streetsCount = 0;
        let page = 1;
        let tmpStreets:{label: string, value: string}[] = []
        do
        {
            const npCities = await novaPoshtaService.getStreets(value, '', limit, page, currentLang)
            tmpStreets = tmpStreets.concat(npCities.map(item => ({label: item.name, value: item.id})))
            //setCities(prevCities => [...prevCities, ...npCities.map(item => ({label: item.name, value: item.id}))])
            if (npCities.length == 0) {
                setBranches([]);
            }
            streetsCount = npCities.length;
            page++;
        } while (streetsCount == limit)
        setStreets(tmpStreets)
        return tmpStreets
    }

    useEffect(() => {
        novaPoshtaService.getCities(selectedArea)
            .then((data) => {
                setCities(data.map(item => ({ label: item.name, value: item.id })))
                if (shippingAddress?.cityId){
                    const city = data.find(c => c.id == shippingAddress.cityId)
                    setSelectedCity({name: city?.name ?? null, value: shippingAddress.cityId})
                }
                if (cities.length == 0) {
                    setBranches([]);
                }
            })

    }, [selectedArea])

    useEffect(() => {
        if (address.deliveryType == DeliveryType.BranchDelivery) {
            novaPoshtaService.getBranches(selectedCity.name!, '', currentLang, weight).then(data => {
                setBranches(data.map(item => ({label: item.name, value: item.id})))
                if (shippingAddress?.carrierBranchId) {
                    setSelectedBranch(shippingAddress.carrierBranchId)
                }
            })
        } else {
            getStreets(selectedCity.value!).then(data => {
                if (shippingAddress?.streetId) {
                    setSelectedStreet(shippingAddress.streetId)
                }
            })
        }

    }, [selectedCity.name, tabIndex])

    const areaChange = async (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): Promise<void> => {
        if (item.label && value) {
            setAddress({...address, state: item.label.toString(), stateId: value})
            setSelectedArea(value)
        }

    }

    const cityChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): void => {
        if (item.label && value) {
            setAddress({...address, city: item.label.toString(), cityId: value})
            setSelectedCity({name: item.label.toString(), value: value})
        }
    }

    const branchChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): void => {
        if (item.label && value) {
            setAddress({...address, carrierBranch: item.label.toString(), carrierBranchId: value})
            setSelectedBranch(value)
        }
    }

    const [deliveryPayerVal, setDeliveryPayerVal] = useState<number | null>(null)
    const [orderAmountVal, setOrderAmountVal] = useState<number | null>(null)

    const deliveryPayerChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>) => {
        setDeliveryPayerVal(+value!)
        if (onPaymentAndPickupDataChange) {
            onPaymentAndPickupDataChange({
                deliveryPayer: +value!,
                orderToPay: orderAmountVal,
                carModel: null,
                carNumber: null,
                pickupDate: null,
                pickupTime: null
            })
        }
    }

    const setOrderAmount = (value: string , event: any) => {
        setOrderAmountVal(+value)
        if(onPaymentAndPickupDataChange) {
            onPaymentAndPickupDataChange({
                deliveryPayer: deliveryPayerVal,
                orderToPay: +value,
                carModel: null,
                carNumber: null,
                pickupDate: null,
                pickupTime: null
            })
        }
    }

    const streetChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>) => {
        if (item.label && value) {
            setAddress({...address, street: item.label.toString(), streetId: value})
            setSelectedStreet(value)
        }
    }

    const houseChange = (value: string , event: any) => {
        if (value) setAddress({...address, house: value})
    }

    const apartmentChange = (value: string , event: any) => {
        if (value) setAddress({...address, apartment: value})
    }

    const [isSaved, setIsSaved] = useState(false)
    const saveData = () => {

        if (formRef.current!.check()) {
            onChange({...address})

            setIsSaved(true)
        }

    }

    const changeTab = (index: number) => {

        setTabIndex(index)
        if(index == 0) {
            setFormError({})
            setFormValue(prevState => ({...prevState, area: selectedArea}))
            setFormValue(prevState => ({...prevState, city: selectedCity.name}))
            setFormValue(prevState => ({...prevState, branch: selectedBranch}))
            setAddress(address => ({...address, house: ''}))
            setAddress(address => ({...address, street: ''}))
            setAddress(address => ({...address, apartment: ''}))

        } else {
            setFormErrorAddress({})
            setFormValueCourier(prevState => ({...prevState, area: selectedArea}))
            setFormValueCourier(prevState => ({...prevState, city: selectedCity.name}))
            setAddress(address => ({...address, carrierBranch: ''}))
            setAddress(address => ({...address, carrierBranchId: null}))
        }
    }

    const saveDeliveryType = (deliveryTypeEnum: number) => {
        setAddress(({...address, deliveryType: deliveryTypeEnum}))
    }

    const searchBy = (keyword: string, label: ReactNode, item: ItemDataType) => {
        if(item.label) {
            return (item.label as string).toLowerCase().startsWith(keyword.toLowerCase());
        }
        return false;
    }


    const focusInput = (el: any) => {

        const inputs = document.getElementsByClassName('rs-picker-search-bar-input')
        for (let i = 0; i < inputs.length; i++) {
            const slide = inputs[i] as HTMLElement;
            slide.focus();
        }
    }


    /*const [isChangeBuDefault, setIsChangeBuDefault] = useState(user.useNPNameByDefault)

    const changeByDefault = (checked: boolean, event: any) => {

        setIsChangeBuDefault(checked)

    }*/


    return (
        <div className="delivery-services-item delivery-newpost-wrapper">

            {/*{user.npClientName == null ?
                <div>
                    <p className='mt-15'><strong>Отправлять от имени клиента:</strong> {user.npClientName}</p>
                    <div className="mt-15">
                        <Toggle checked={isChangeBuDefault} onChange={changeByDefault} className="comparison-difference" />
                    </div>
                </div>
                : null
            }*/}

            {isSaved ? null : <Tabs selectedIndex={tabIndex} onSelect={(index) => changeTab(index)}>
                <TabList>
                    <Tab onClick={() => saveDeliveryType(0)}>{t('delivery.stockDelivery')}</Tab>
                    <Tab  onClick={() => saveDeliveryType(1)}>{t('delivery.addressDelivery')}</Tab>
                </TabList>

                <TabPanel>
                    <Form model={model} ref={formRef} onCheck={setFormError} formDefaultValue={formValue}>

                        <div className={formError.hasOwnProperty('area') ? "input-wrapper selectpicker input-area has-error" : "input-wrapper selectpicker input-area"}>
                            <Field
                                name="area"
                                label={area}
                                accepter={SelectPicker}
                                data={areas}
                                value={selectedArea}
                                onSelect={areaChange}
                                onOpen={focusInput}
                                defaultValue={address.state}
                            />
                        </div>

                        <div className={formError.hasOwnProperty('city') ? "input-wrapper selectpicker input-city has-error" : "input-wrapper selectpicker input-city"}>
                            <Field
                                name="city"
                                label={city}
                                accepter={SelectPicker}
                                value={selectedCity.value}
                                data={cities}
                                searchBy={searchBy}
                                onSelect={cityChange}
                                defaultValue={address.city}
                                onOpen={focusInput}
                            />
                        </div>

                        <div className={formError.hasOwnProperty('branch') ? "input-wrapper selectpicker input-branch has-error" : "input-wrapper selectpicker input-branch"}>
                            <Field
                                name="branch"
                                label={branch}
                                accepter={SelectPicker}
                                value={selectedBranch}
                                data={branches}
                                onSelect={branchChange}
                                onOpen={focusInput}
                            />
                        </div>

                        {onPaymentAndPickupDataChange ?
                        <div className="input-wrapper selectpicker input-payer">
                            <Field
                                name="payer"
                                label={payer}
                                accepter={SelectPicker}
                                defaultValue={1}
                                searchable={false}
                                data={deliveryPayer}
                                onSelect={deliveryPayerChange}
                                onOpen={focusInput}
                            />
                        </div> : null}

                        {onPaymentAndPickupDataChange && showPayOnDelivery ?
                            <div className="input-wrapper input-sum">
                            <Field
                                name="sum"
                                label={sum}
                                accepter={Input}
                                onChange={setOrderAmount}
                            />
                        </div>  : null}

                        {onPaymentAndPickupDataChange && showPayOnDelivery ?
                            <div className="pay-method-info">
                            <p>{t('order.payMethod')}</p>
                        </div>  : null}


                        <div className="btn-wrapper">
                            <button  disabled={Object.keys(formError).length ? true: false}
                                     type="submit"
                                     className="btn btn-lg"
                                     onClick={saveData}>
                                {t('order.save')}
                            </button>
                        </div>
                    </Form>
                </TabPanel>
                <TabPanel>
                    <Form model={modelAddress}  ref={formRef} onCheck={setFormErrorAddress}  formDefaultValue={formValueCourier}>
                        <div className={formErrorAddress.hasOwnProperty('area') ? "input-wrapper selectpicker input-area has-error" : "input-wrapper selectpicker input-area"}>
                            <Field
                                name="area"
                                label={area}
                                accepter={SelectPicker}
                                data={areas}
                                value={selectedArea}
                                onSelect={areaChange}
                                onOpen={focusInput}
                            />
                        </div>

                        <div className={formErrorAddress.hasOwnProperty('city') ? "input-wrapper selectpicker input-city has-error" : "input-wrapper selectpicker input-city"}>
                            <Field
                                name="city"
                                label={city}
                                accepter={SelectPicker}
                                data={cities}
                                searchBy={searchBy}
                                value={selectedCity.value}
                                onSelect={cityChange}
                                onOpen={focusInput}
                            />
                        </div>

                        <div className={formErrorAddress.hasOwnProperty('street') ? "input-wrapper selectpicker input-street has-error" : "input-wrapper selectpicker input-street"}>
                            <Field
                                name="street"
                                label={street}
                                accepter={SelectPicker}
                                data={streets}
                                value={selectedStreet}
                                onSelect={streetChange}
                                onOpen={focusInput}
                            />
                        </div>

                        {shippingAddress ?  <div className={formErrorAddress.hasOwnProperty('house') ? "input-wrapper input-house has-error" : "input-wrapper input-house"}>
                            <Field
                                name="house"
                                label={house}
                                accepter={Input}
                                defaultValue={shippingAddress?.house}
                                onChange={houseChange}
                            />
                        </div> : <div className={formErrorAddress.hasOwnProperty('house') ? "input-wrapper input-house has-error" : "input-wrapper input-house"}>
                            <Field
                                name="house"
                                label={house}
                                accepter={Input}
                                //value={address.house}
                                onChange={houseChange}
                            />
                        </div> }

                        {shippingAddress ?  <div className={formErrorAddress.hasOwnProperty('apartment') ? "input-wrapper input-apartment has-error" : "input-wrapper input-apartment"}>
                            <Field
                                name="apartment"
                                label={apartment}
                                accepter={Input}
                                defaultValue={shippingAddress?.apartment}
                                onChange={apartmentChange}
                            />
                        </div> : <div className={formErrorAddress.hasOwnProperty('apartment') ? "input-wrapper input-apartment has-error" : "input-wrapper input-apartment"}>
                            <Field
                                name="apartment"
                                label={apartment}
                                accepter={Input}
                                //value={address.apartment}
                                onChange={apartmentChange}
                            />
                        </div>}

                        {onPaymentAndPickupDataChange ?
                            <div className="input-wrapper selectpicker input-payer">
                                <Field
                                    name="payer"
                                    label={payer}
                                    accepter={SelectPicker}
                                    searchable={false}
                                    data={deliveryPayer}
                                    defaultValue={1}
                                    onSelect={deliveryPayerChange}
                                    onOpen={focusInput}
                                />
                            </div> : null}

                        {onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="input-wrapper input-sum">
                                <Field
                                    name="sum"
                                    label={sum}
                                    accepter={Input}
                                    onChange={setOrderAmount}
                                />
                            </div> : null}

                            {onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="pay-method-info">
                                <p>{t('order.payMethod')}</p>
                            </div> : null}


                        <div className="btn-wrapper">
                            <button
                                disabled={Object.keys(formErrorAddress).length ? true: false}
                                type="submit"
                                className="btn btn-lg"
                                onClick={saveData}>
                                {t('order.save')}
                            </button>
                        </div>
                    </Form>
                </TabPanel>

            </Tabs>}


        </div>
    )
}

export default DeliveryNewPost