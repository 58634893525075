import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../hooks";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import {Form, Input, Schema, SelectPicker} from "rsuite";
import {ItemDataType} from "rsuite/cjs/@types/common";

import deliveryService from "../../../../../services/deliveryService";

import ShippingAddress from "../../../../../interfaces/ShippingAddress";
import {CarrierType} from "../../../../../enums/CarrierType";

import {DeliveryType} from "../../../../../enums/DeliveryType";
import PaymentAndPickupData from "../../../../../interfaces/PaymentAndPickupData";


interface SelectItem{
    label: string,
    value: string
}

const Field = React.forwardRef((props: any, ref: any) => {
    const { name, message, label, accepter, error, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : ''}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control checkAsync={false} name={name} accepter={accepter} errorMessage={error} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});


const DeliveryDelivery:React.FC<{onChange: Function, shippingAddress?: ShippingAddress | null,
    onPaymentAndPickupDataChange?: (data: PaymentAndPickupData) => void,
    showPayOnDelivery: boolean}> = (
    {onChange, shippingAddress, onPaymentAndPickupDataChange, showPayOnDelivery}
) => {
    const {t} = useTranslation();
    const currentLang = useAppSelector<string>((state) => state.currentLang.value);
    const [tabIndex, setTabIndex] = useState(0);
    const [areas, setAreas] = useState<SelectItem[]>([])
    const [cities, setCities] = useState<SelectItem[]>([])
    const [branches, setBranches] = useState<SelectItem[]>([])
    const [deliveryPayer, setDeliveryPayer] = useState([{label: t('deliveryPayer.sender'), value: 0}, {label: t('deliveryPayer.receiver'), value: 1}])
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');

    const [address, setAddress] = useState<ShippingAddress>({
        carrierType: CarrierType.Delivery,
        deliveryType: DeliveryType.BranchDelivery,
        id: shippingAddress ? shippingAddress.id : null,
        state: shippingAddress ? shippingAddress.state : '',
        stateId:  shippingAddress ? shippingAddress.stateId : null,
        city: shippingAddress ? shippingAddress.city : '',
        cityId: shippingAddress ? shippingAddress.cityId : null,
        district: '',
        districtId: null,
        postalCode: null,
        street: shippingAddress ? shippingAddress.street : '',
        streetId: shippingAddress ? shippingAddress.streetId : null,
        house: shippingAddress ? shippingAddress.house : '',
        apartment: shippingAddress ? shippingAddress.apartment : '',
        carrierBranch: shippingAddress ? shippingAddress.carrierBranch : '',
        carrierBranchId: shippingAddress ? shippingAddress.carrierBranchId : null,
        country: 'Ukraine'
    })




    const formRef = useRef<any>();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({
        area: shippingAddress?.stateId,
        city: shippingAddress?.cityId,
        branch: shippingAddress?.carrierBranchId
    });
    const [formValueCourier, setFormValueCourier] = useState({
        area: shippingAddress?.stateId,
        city: shippingAddress?.cityId,
        street: shippingAddress?.street,
        house: shippingAddress?.house
    });
    const [formErrorAddress, setFormErrorAddress] = useState({});

    const area = t('delivery.area')
    const city = t('delivery.city')
    const branch = t('delivery.branch')
    const street = t('delivery.street')
    const house = t('delivery.house')
    const apartment = t('delivery.apartment')
    const payer = t('delivery.payer')
    const sum = t('delivery.sum')

    const { StringType } = Schema.Types;
    const errorField = t('form.requiredFieldMsg')
    const model = Schema.Model({
        area: StringType().isRequired(errorField),
        city: StringType().isRequired(errorField),
        branch: StringType().isRequired(errorField),
        //apartment: StringType().isRequired(errorField),
    });
    const modelAddress = Schema.Model({
        area: StringType().isRequired(errorField),
        city: StringType().isRequired(errorField),
        street: StringType().isRequired(errorField),
        house: StringType().isRequired(errorField),
        //apartment: StringType().isRequired(errorField),
    });

    useEffect(() => {
        if (shippingAddress){
            setTabIndex(shippingAddress.deliveryType )
            saveDeliveryType(shippingAddress.deliveryType)
        }
    }, [])

    useEffect(() => {
        deliveryService.getAreas(currentLang).then(data => {
            setAreas(data.map(item => ({ label: item.name, value: item.id, uid: item.uid })))
            if(shippingAddress?.stateId) {
                let selected = data.find(item => item.uid == shippingAddress.stateId);
                if(selected) {
                    setSelectedArea(selected.id)
                }
            }
            if (data.length == 0) {
                setCities([]);
                setBranches([]);
            }
        })
    }, [])

    useEffect(() => {
        deliveryService.getCities(selectedArea as string, currentLang).then(data => {
            setCities(data.map(item => ({ label: item.name, value: item.id })))
            if (shippingAddress?.cityId){
                setSelectedCity(shippingAddress.cityId)
            }
            if (data.length == 0) {
                setBranches([]);
            }
        })
    }, [selectedArea])

    useEffect(() => {
        deliveryService.getBranches(selectedCity as string,'', currentLang).then(data => {
            setBranches(data.map(item => ({ label: item.name, value: item.id })))

            if (shippingAddress?.carrierBranchId){
                setSelectedBranch(shippingAddress.carrierBranchId)
            }
        })
    }, [selectedCity, tabIndex])

    const areaChange = (value: string | null, item: any, event: SyntheticEvent<Element, Event>): void => {
        if (item.label && value) {
            setAddress({...address, state: item.label.toString(), stateId: item.uid})
            setSelectedArea(value)
        }
    }

    const cityChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): void => {
        if (item.label && value) {
            setAddress({...address, city: item.label.toString(), cityId: value})
            setSelectedCity(value)
        }
    }

    const branchChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): void => {
        if (item.label && value) {
            setAddress({...address, carrierBranch: item.label.toString(), carrierBranchId: value})
            setSelectedBranch(value)
        }

    }

    const [deliveryPayerVal, setDeliveryPayerVal] = useState<number | null>(null)
    const [orderAmountVal, setOrderAmountVal] = useState<number | null>(null)

    const deliveryPayerChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>) => {
        setDeliveryPayerVal(+value!)
        if(onPaymentAndPickupDataChange) {
            onPaymentAndPickupDataChange({
                deliveryPayer: +value!,
                orderToPay: orderAmountVal,
                carModel: null,
                carNumber: null,
                pickupDate: null,
                pickupTime: null
            })
        }
    }

    const setOrderAmount = (value: string , event: any) => {
        setOrderAmountVal(+value)
        if(onPaymentAndPickupDataChange) onPaymentAndPickupDataChange({
            deliveryPayer: deliveryPayerVal,
            orderToPay: +value,
            carModel: null,
            carNumber: null,
            pickupDate: null,
            pickupTime: null
        })

    }

    const streetChange = (value: string , event: any) => {
        if (value) setAddress({...address, street: value})
    }

    const houseChange = (value: string , event: any) => {
        if (value) setAddress({...address, house: value})
    }

    const apartmentChange = (value: string , event: any) => {
        if (value) setAddress({...address, apartment: value})
    }

    const [isSaved, setIsSaved] = useState(false)

    const saveData = () => {
        if (formRef.current!.check()) {
            onChange({...address})
            setIsSaved(true)
        }
    }

    const changeTab = (index: number) => {

        setTabIndex(index)
        if(index == 0) {
            setFormError({})
            setFormValue(prevState => ({...prevState, area: selectedArea}))
            setFormValue(prevState => ({...prevState, city: selectedCity}))
            setFormValue(prevState => ({...prevState, branch: selectedBranch}))
            setAddress(address => ({...address, house: ''}))
            setAddress(address => ({...address, street: ''}))
            setAddress(address => ({...address, apartment: ''}))
        }
        else {
            setFormErrorAddress({})
            setFormValueCourier(prevState => ({...prevState, area: selectedArea}))
            setFormValueCourier(prevState => ({...prevState, city: selectedCity}))
            setAddress(address => ({...address, carrierBranch: ''}))
            setAddress(address => ({...address, carrierBranchId: null}))
        }
    }

    const saveDeliveryType = (deliveryTypeEnum: number) => {
        setAddress(({...address, deliveryType: deliveryTypeEnum}))
    }

    const focusInput = (el: any) => {

        const inputs = document.getElementsByClassName('rs-picker-search-bar-input')
        for (let i = 0; i < inputs.length; i++) {
            const slide = inputs[i] as HTMLElement;
            slide.focus();
        }
    }

    return (
        <div className="delivery-services-item delivery-meest-wrapper">
            {isSaved ? null : <Tabs selectedIndex={tabIndex} onSelect={(index) => changeTab(index)}>
                <TabList>
                    <Tab onClick={() => saveDeliveryType(0)}>{t('delivery.stockDelivery')}</Tab>
                    <Tab onClick={() => saveDeliveryType(1)}>{t('delivery.addressDelivery')}</Tab>
                </TabList>

                <TabPanel>
                    <Form model={model} ref={formRef} onCheck={setFormError} formDefaultValue={formValue}>
                        <div  className={formError.hasOwnProperty('area') ? "input-wrapper selectpicker input-area has-error" : "input-wrapper selectpicker input-area"}>
                            <Field
                                name="area"
                                label={area}
                                value={selectedArea}
                                accepter={SelectPicker}
                                data={areas}
                                onSelect={areaChange}
                                onOpen={focusInput}
                            />
                        </div>

                        <div className={formError.hasOwnProperty('city') ? "input-wrapper selectpicker input-city has-error" : "input-wrapper selectpicker input-city"}>
                            <Field
                                name="city"
                                label={city}
                                value={selectedCity}
                                accepter={SelectPicker}
                                data={cities}
                                onSelect={cityChange}
                                onOpen={focusInput}
                            />
                        </div>

                        <div className={formError.hasOwnProperty('branch') ? "input-wrapper selectpicker input-branch has-error" : "input-wrapper selectpicker input-branch"}>
                            <Field
                                name="branch"
                                label={branch}
                                value={selectedBranch}
                                accepter={SelectPicker}
                                data={branches}
                                onSelect={branchChange}
                                onOpen={focusInput}
                            />
                        </div>

                            {onPaymentAndPickupDataChange ? <div className="input-wrapper selectpicker input-payer">
                                <Field
                                    name="payer"
                                    label={payer}
                                    accepter={SelectPicker}
                                    searchable={false}
                                    defaultValue={1}
                                    data={deliveryPayer}
                                    onSelect={deliveryPayerChange}
                                    onOpen={focusInput}
                                />
                            </div> : null}

                            {/*{onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="input-wrapper input-sum">
                                <Field
                                    name="sum"
                                    label={sum}
                                    accepter={Input}
                                    onChange={setOrderAmount}
                                />
                            </div> : null }
                            {onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="pay-method-info">
                                <p>{t('order.payMethod')}</p>
                            </div>: null }*/}

                        <div className="btn-wrapper">
                            <button
                                disabled={Object.keys(formError).length ? true: false}
                                type="submit"
                                className="btn btn-lg"
                                onClick={saveData}>
                                {t('order.save')}
                            </button>
                        </div>

                    </Form>
                </TabPanel>
                <TabPanel>
                    <Form model={modelAddress} ref={formRef} onCheck={setFormErrorAddress} formDefaultValue={formValueCourier}>
                        <div className={formErrorAddress.hasOwnProperty('area') ? "input-wrapper selectpicker input-area has-error" : "input-wrapper selectpicker input-area"}>
                            <Field
                                name="area"
                                label={area}
                                value={selectedArea}
                                accepter={SelectPicker}
                                data={areas}
                                onSelect={areaChange}
                                onOpen={focusInput}
                            />
                        </div>

                        <div className={formErrorAddress.hasOwnProperty('city') ? "input-wrapper selectpicker input-city has-error" : "input-wrapper selectpicker input-city"}>
                            <Field
                                name="city"
                                label={city}
                                value={selectedCity}
                                accepter={SelectPicker}
                                data={cities}
                                onSelect={cityChange}
                                onOpen={focusInput}
                            />
                        </div>

                        {shippingAddress ? <div className={formErrorAddress.hasOwnProperty('street') ? "input-wrapper input-street has-error" : "input-wrapper input-street"}>
                            <Field
                                name="street"
                                label={street}
                                accepter={Input}
                                defaultValue={shippingAddress?.street}
                                onChange={streetChange}
                            />
                        </div> : <div className={formErrorAddress.hasOwnProperty('street') ? "input-wrapper input-street has-error" : "input-wrapper input-street"}>
                            <Field
                                name="street"
                                label={street}
                                accepter={Input}
                                //value={address.address}
                                onChange={streetChange}
                            />
                        </div>}


                        {shippingAddress ? <div className={formErrorAddress.hasOwnProperty('house') ? "input-wrapper input-house has-error" : "input-wrapper input-house"}>
                            <Field
                                name="house"
                                label={house}
                                defaultValue={shippingAddress?.house}
                                accepter={Input}
                                onChange={houseChange}
                            />
                        </div> : <div className={formErrorAddress.hasOwnProperty('house') ? "input-wrapper input-house has-error" : "input-wrapper input-house"}>
                            <Field
                                name="house"
                                label={house}
                                //value={address.house}
                                accepter={Input}
                                onChange={houseChange}
                            />
                        </div>}

                        {shippingAddress ? <div  className={formErrorAddress.hasOwnProperty('apartment') ? "input-wrapper input-apartment has-error" : "input-wrapper input-apartment"}>
                            <Field
                                name="apartment"
                                label={apartment}
                                defaultValue={shippingAddress?.apartment}
                                accepter={Input}
                                onChange={apartmentChange}
                            />
                        </div> : <div className={formErrorAddress.hasOwnProperty('apartment') ? "input-wrapper input-apartment has-error" : "input-wrapper input-apartment"}>
                            <Field
                                name="apartment"
                                label={apartment}
                                //value={address.apartment}
                                accepter={Input}
                                onChange={apartmentChange}
                            />
                        </div>}

                        {onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="input-wrapper selectpicker input-payer">
                                <Field
                                    name="payer"
                                    label={payer}
                                    accepter={SelectPicker}
                                    searchable={false}
                                    defaultValue={1}
                                    data={deliveryPayer}
                                    onSelect={deliveryPayerChange}
                                    onOpen={focusInput}
                                />
                            </div>: null}

                            {/*{onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="input-wrapper input-sum">
                                <Field
                                    name="sum"
                                    label={sum}
                                    accepter={Input}
                                    onChange={setOrderAmount}
                                />
                            </div>: null}
                            {onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="pay-method-info">
                                <p>{t('order.payMethod')}</p>
                            </div>: null}*/}

                        <div className="btn-wrapper">
                            <button
                                disabled={Object.keys(formErrorAddress).length ? true: false}
                                type="submit"
                                className="btn btn-lg"
                                onClick={saveData}>
                                {t('order.save')}
                            </button>
                        </div>
                    </Form>
                </TabPanel>

            </Tabs>}


        </div>
    )
}
export default DeliveryDelivery