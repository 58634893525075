import React, {useEffect, useState, useRef} from "react";

import { useTranslation } from 'react-i18next';
import i18next from "i18next";

import {NavLink, useParams} from "react-router-dom";
import {ButtonToolbar, Dropdown} from "rsuite";

import {useAppDispatch, useAppSelector} from "../../hooks";
import {setCategoriesAsync} from "../../slices/actions/categoryAsyncActions";
import {setCurrentLanguage} from "../../slices/currentLangSlice"

import {Category} from "../../interfaces/Category";


import CategoriesListMenu from "../../components/CategoryListMenu/CategoryListMenu"
import Autocomplete from "../../components/Autocomplete/Autocomplete"
import Sidebar from "../Sidebar/Sidebar";

import Velotrade from '../../assets/img/Velotrade.svg';
import VelotradeMobile from '../../assets/img/Velotrade_mob.svg';

import '../../App.sass'
import './Header.sass'
import {CartOrder} from "../../interfaces/CartProduct";
import ComparisonCategory from "../../interfaces/ComparisonCategory";
import AuthProvider from "../../services/AuthProvider";
import {setChosenFiltersObj} from "../../slices/chosenFiltersSlice";
import {PermissionsConstants} from "../../const/Permissions";


const Header: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)
    const permission = AuthProvider.getPermissions()

    const[isAdmin, setAdmin] = useState(false)



    const [isMobileView, setIsMobileView] = useState(false)
    const [isMenuShow, setIsMenuShow] = useState(true)
    const [activeMenu, setActiveMenu] = useState(true)
    const [activeCart, setActiveCart] = useState(false)

    const profileMenu = useRef<any>(null)

    let {categoryId} = useParams();
    useEffect(() => {
        setCategoryList(false)
    }, [categoryId])

    const closeOpenMenus = (e: MouseEvent)=>{
        if(profileMenu.current && activeMenu && !profileMenu.current?.contains(e.target) && deviceWidth <= 1400){
            setActiveMenu(false)
        }
    }


    useEffect(() => {
        document.addEventListener('click', closeOpenMenus,false)
        return () => {
            document.removeEventListener("click", closeOpenMenus, false);
        };

    }, []);


    useEffect(() => {
        if(deviceWidth <= 860) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

        if(deviceWidth > 1400){
            if(isAdmin){
                setActiveMenu(false)
                setIsMenuShow(false)
            }
            else {
                setActiveMenu(true)
                setIsMenuShow(true)
            }
        }
        else if (deviceWidth <= 1400 && deviceWidth > 860){
            setActiveMenu(false)
            setIsMenuShow(false)

        }
        else {
            setActiveMenu(false)
            setIsMenuShow(false)
        }

    }, [deviceWidth])

    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])


    const currentLanguage = useAppSelector<string>(state => state.currentLang.value)

    const [currentLang, setCurrentLang] = useState('Укр')
    const [options, setOptions] = useState<{value: string; label: string; }[]>([]);

    console.log('currentLanguage', currentLanguage);

    useEffect(() => {
        if (currentLanguage == 'pl') {
            setCurrentLang('Pol')
        }
        else {
            setCurrentLang('Укр')
        }

        if(process.env.REACT_APP_LOCATION == "ua"){
            setOptions([
                { value: 'ru', label: 'Рус' },
                { value: 'uk', label: 'Укр' }
            ])
        } else {
            setOptions([
                { value: 'pl', label: 'Pol' },
                { value: 'uk', label: 'Укр' },
                { value: 'ru', label: 'Рус' },
                { value: 'en', label: 'Eng' }
            ])
        }

    }, [currentLanguage])

    const changeLang = (lng: {value: string, label: string}) => {
        if (currentLang !== lng.value) {
            i18next.changeLanguage(lng.value);
            setCurrentLang(lng.label)
            dispatch(setCurrentLanguage(lng.value))
            AuthProvider.updateLanguage(lng.value)
        }
    };

    const rate = useAppSelector<number>(state => state.rate.value)
    const compare = useAppSelector<ComparisonCategory[]>(state => state.compare.value)
    const categories = useAppSelector<Category[]>((state) => state.categories.value);

    const [categoryList, setCategoryList] = useState(false)
    const toggleCatalog = () =>{

        setCategoryList(categoryList => !categoryList)
        setActiveSearch(false)

        if (isAdmin){
            setActiveMenu(false)
        }

        if (isMobileView){
            setActiveSearch(false)
            setActiveCart(false)
            setIsMenuShow(false)
        }

    }

    const closeCategoryList = () => {
        setCategoryList(false)
        setActiveMenu(false)
    }

    useEffect(() => {
        dispatch(setCategoriesAsync());
    }, [currentLanguage]);


    const toggleMenu = () => {
        setActiveMenu(activeMenu => !activeMenu)
        setIsMenuShow(isMenuShow => !isMenuShow)
        setCategoryList(false)
        if (isMobileView) {
            setActiveSearch(false)
            setActiveCart(false)
        }
    }

    const [activeSearch, setActiveSearch] = useState(false)
    const [showActiveSearchMobile, setShowActiveSearchMobile] = useState(false)

    const openSearch = ()  => {
        if (isMobileView) {
            setActiveMenu(false)
            setCategoryList(false)
            setActiveCart(false)
            setIsMenuShow(false)
        }
        setActiveSearch(true)
        setCategoryList(false)
        if (deviceWidth <= 860 && activeSearch){
            setShowActiveSearchMobile(showActiveSearchMobile => !showActiveSearchMobile)
            setActiveSearch(activeSearch => !activeSearch)
        }
    }

    const closeActiveSearch = (state: boolean) => {
        setActiveSearch(state)
        if (isMobileView) {
            setCategoryList(false)
            setActiveMenu(false)
            setActiveCart(false)
        }
    }

    const closeSeacrh = () => {

    }

    const toggleCart = () => {
        if(isMobileView) {
            setCategoryList(false)
            setActiveMenu(false)
            setIsMenuShow(false)
            setActiveSearch(false)
            setActiveCart(activeCart => !activeCart)
        }
    }

    const closeSearch = () => {
        closeActiveSearch(false)
    }


    const productsCart = useAppSelector<CartOrder[]>((state) => state.cart.value);
    const activeOrder = useAppSelector<number>(state => state.activeOrder.value)
    let totalProducts = 0;

    if (productsCart.length != 0) {
        productsCart.forEach((order, index) => {
            order.orderItems.forEach((products, idx) => {
                totalProducts += products.count
            })
        })
    }

    const [countCompareItems, setCountCompareItems] = useState(0)

    useEffect(() => {
        let count = 0
        if(compare && compare.length != 0) {
            compare.forEach((category, index) => {
                count += category.items.length
            })
        }
        setCountCompareItems(count)
    }, [compare])

    const clearFilters = () => {
        dispatch(setChosenFiltersObj([]))
    }


    useEffect(() => {
        if (permission.indexOf(PermissionsConstants.ViewAdmin) != -1) {
            setAdmin(true)
            setActiveMenu(false)
        }
    }, [])


    return (
        <div className="header-wrapper">
            <header>
                <NavLink to="/" className={"logo"} onClick={clearFilters}>
                    {isMobileView ? <img src={VelotradeMobile} alt="Velotrade"/> : <img src={Velotrade} alt="Velotrade"/> }
                </NavLink>

                <button className={categoryList ? "btn btn-lg btn-catalog open" : "btn btn-lg btn-catalog"} onClick={toggleCatalog}>
                    <span className={"burger"}></span>
                    <span className={"catalog-text"}>{isMobileView ? t('catalog_mob') : t('catalog')}</span>
                </button>

                <div className={activeSearch ? "active search-wrapper" : "search-wrapper"}>
                    <button className={"openSearch hideDesktopSearch"} onClick={openSearch}>{t('autocomplete.search')}</button>
                    <Autocomplete
                        openSearch={setActiveSearch}
                        closeActiveSearch={closeActiveSearch}
                        activeSearch={activeSearch}
                        setCategoryList={setCategoryList}
                    />
                </div>
                {isAdmin ?
                    activeSearch  && !isMobileView ? null : <div className="menu admin" ref={profileMenu}>
                        <div className={"menu-desktop"}>
                            <button className={activeMenu ? "openProfile hideDesktop open" : "openProfile hideDesktop"} onClick={toggleMenu}>{t('menu_header.cabinet')}</button>

                            {activeMenu ? <nav className={"nav-desktop"}>

                                <NavLink to="/admin-edit/">Adm: Редактор</NavLink>
                                <NavLink to="/admin-orders/">Adm: {t('menu_header.orders')}</NavLink>
                                <NavLink to="/admin-returns/">Adm: {t('return.return')}</NavLink>
                                <NavLink to="/admin-reserve/">Adm: {t('myOrders.myReserveTitle')}</NavLink>
                                <NavLink to="/admin-managers/">Adm: {t('manager.manager')}</NavLink>
                                <NavLink to="/admin-updates/">Adm: {t('updates.updates')}</NavLink>

                                <NavLink to="/profile"  className={({ isActive, isPending}) =>
                                    isPending ? "pending hideDesktop" : isActive ? "active hideDesktop" : "hideDesktop"
                                }>{t('menu_header.profile')}</NavLink>
                                <NavLink to="/my-orders"  className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>{t('menu_header.orders')}</NavLink>

                                {permission.indexOf(PermissionsConstants.ViewTransactions) != -1 ?
                                    <NavLink to="/settlements/"  className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }>{t('menu_header.transactions')}</NavLink> : null}

                                {permission.indexOf(PermissionsConstants.ViewReserves) != -1 ?
                                    <NavLink to="/my-reserve/"  className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }>{t('menu_header.reserves')}</NavLink> : null}

                                {permission.indexOf(PermissionsConstants.ViewReclamations) != -1 ?
                                    <NavLink to="/return/"  className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }>{t('menu_header.reclamations')}</NavLink> : null}

                                <NavLink to="/help/"  className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>{t('menu_header.help')}</NavLink>

                                {permission.indexOf(PermissionsConstants.ViewSubscriptions) != -1 ?
                                    <NavLink to="/subscription/"  className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }>{t('menu_header.subscriptions')}</NavLink> : null}

                                <NavLink to="/my-managers/"  className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>{t('menu_header.myManager')}</NavLink>
                            </nav> : null}
                        </div>
                    </div>
                    :
                    activeSearch && !isMobileView ? null : <div className="menu" ref={profileMenu}>
                        <div className={"menu-desktop"}>
                            <button className={activeMenu ? "openProfile hideDesktop open" : "openProfile hideDesktop"} onClick={toggleMenu}>{t('menu_header.cabinet')}</button>

                            {isMenuShow ? <nav className={"nav-desktop"}>

                                <NavLink to="/profile"  className={({ isActive, isPending}) =>
                                    isPending ? "pending hideDesktop" : isActive ? "active hideDesktop" : "hideDesktop"
                                }>{t('menu_header.profile')}</NavLink>
                                <NavLink to="/my-orders"  className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>{t('menu_header.orders')}</NavLink>

                                {permission.indexOf(PermissionsConstants.ViewTransactions) != -1 ?
                                    <NavLink to="/settlements/"  className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }>{t('menu_header.transactions')}</NavLink> : null}

                            {/*{permission.indexOf(PermissionsConstants.ViewReserves) != -1 ?
                            <NavLink to="/my-reserve/"  className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active" : ""
                            }>{t('menu_header.reserves')}</NavLink> : null}*/}

                                {permission.indexOf(PermissionsConstants.ViewReclamations) != -1 ?
                                    <NavLink to="/return/"  className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }>{t('menu_header.reclamations')}</NavLink> : null}

                                <NavLink to="/help/"  className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>{t('menu_header.help')}</NavLink>

                                {permission.indexOf(PermissionsConstants.ViewSubscriptions) != -1 ?
                                    <NavLink to="/subscription/"  className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }>{t('menu_header.subscriptions')}</NavLink> : null}

                                <NavLink to="/my-managers/"  className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>{t('menu_header.myManager')}</NavLink>
                            </nav> : null}

                        </div>

                    </div>}

                {activeSearch ? "" : <div className={"lang"}>
                    <Dropdown title={currentLang}>
                        {options.map((item, index) => {
                            return (
                                <Dropdown.Item key={index} onSelect={() => changeLang(item)}>{item.label}</Dropdown.Item>
                                )
                        })}
                    </Dropdown>
                </div>}

                {activeSearch ? "" : <div className={"exchange_rate"}><div>{t('exchangeRates')} <span>$ {rate}</span></div></div> }

                <NavLink
                    to="/compare"
                    className={({ isActive, isPending }) =>
                        isPending ? "pending btn-usual compare" : isActive ? "active btn-usual compare" : "btn-usual compare"
                    }
                >
                    {countCompareItems != 0 ? countCompareItems : null}
                </NavLink>

                {isMobileView ? '' : <NavLink
                    to="/cart"
                    className={({ isActive, isPending }) =>
                        isPending ? "pending btn-usual cart" : isActive ? "active btn-usual cart" : "btn-usual cart"
                    }
                >
                    {totalProducts ? <span className="total-products">{totalProducts}</span> : null}
                </NavLink>}

                <NavLink to="/profile" className={"btn-usual profile"}>{t('profile.title')}</NavLink>

            </header>

            {categoryList ? <div className={"side-menu-category"}>
                <CategoriesListMenu
                    children={categories}
                    closeMenu={closeCategoryList}
                    mobileView={isMobileView}
                />
            </div> : ''}
            {categoryList ? <div className={"overflow"} onClick={closeCategoryList}></div> : ''}

            {activeCart ? <div className={"mobileView"}><Sidebar></Sidebar></div> : ''}
            {isMobileView ?
                <button
                    className={activeCart ? "openCart hideDesktopCart open" : "openCart hideDesktopCart"}
                    onClick={toggleCart} >
                    <span className="basket-name">{t('basket.basket')}</span>
                    <span className="total-products">{totalProducts}</span>
                </button> : ''}

        </div>

    )
}
export default Header;